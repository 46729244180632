import React, { useState } from 'react'
import styled from 'styled-components'
import { TextInput, TextArea, InputButton } from '../inputs'

const Form = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
`
const SuccessModal = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 25px;
  right: 25px;
  left: 25px;
  bottom: 25px;
  color: ${({theme}) => theme.darkWhite.hex};
  background: hsla(${({theme}) => theme.darkGray.hsl}, .95);
`

export const ContactForm = () => {
  const initialFormData = {
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  }
  const [formValues, setFormValues] = useState({ ...initialFormData })
  const [didFormSubmit, setDidFormSubmit] = useState(false)

  const handleChange = (event) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
    })
  }

  const encode = (data) => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const handleSubmit = (event) => {
    console.log('event: ', event)
    event.preventDefault()
    const { name } = event.target.form

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": name,
        ...formValues,
      })
    })
    .then(response => console.log(response))
    .catch(err => console.error(err))
    
    setDidFormSubmit(true);
    setFormValues({ ...initialFormData })
  }

  return (      
    <Form
      data-netlify="true" 
      name="contactForm" 
      netlify-honeypot="bot-field" 
    >
      <input type="hidden" name="form-name" value="contactForm" />
      <input type="hidden" name="bot-field" />
      <TextInput
        label="First Name"
        type="text"
        name="firstName"
        value={formValues.firstName}
        onChange={e => handleChange(e)}
      />
      <TextInput
        label="Last Name"
        type="text"
        name="lastName"
        value={formValues.lastName}
        onChange={e => handleChange(e)}
      />
      <TextInput
        label="Email"
        type="email"
        name="email"
        value={formValues.email}
        onChange={e => handleChange(e)}
      />
      <TextArea
        label="Message"
        name="message"
        value={formValues.message}
        onChange={e => handleChange(e)}
        rows="4"
      />
      <InputButton 
        type="button" 
        value="Submit" 
        onClick={e => handleSubmit(e)}
        disabled={didFormSubmit}
      />
      {didFormSubmit && <SuccessModal>Success! Thank you for contacting us.</SuccessModal>}
    </Form>  
  )
}
