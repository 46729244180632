import React from 'react'
import styled from 'styled-components'
import Layout from '../components/layout'
import { H3, StyledHeading } from '../components/page-elements'
import { ContactForm } from '../components/contact-form'
import { GoogleMap } from '../components/google-map'

const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 75vh;
  max-width: 1200px;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 5vh;
  padding-bottom: 5vh;
  padding-right: 5vw;
  padding-left: 5vw;
`
const Inner = styled.div`
  width: 100%;
  max-width: 500px;
  >:first-child {
    margin-bottom: 50px;
  }
  &:nth-child(2) {
    p {
      color: #fff;
      margin-bottom: 2.5vh;
    }
  }
`
const GridWrapper = styled.div`
  display: grid;
  justify-items: center;
  align-items: flex-end;
  grid-gap: 10vh;

  @media(min-width: 1024px) {
    grid-template-columns: 1fr 1fr;
    >:nth-child(2) {
      grid-column: 2;
    }    
  }
`
const Contact = ({ location }) => {
  return (
    <Layout
      title="Contact Us"
      pathname={location.pathname}
    >
      <PageContainer>
        <GridWrapper>
          <Inner>
            <StyledHeading>Contact Us</StyledHeading>
            <ContactForm />
          </Inner>
          <Inner>
            <H3 as="p">Come visit us in Ionia, Michigan.</H3>
            <GoogleMap />
          </Inner>
        </GridWrapper>
      </PageContainer>
    </Layout>
  )
}

export default Contact
