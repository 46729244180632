import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  box-shadow: 5px 5px 0px 0px hsla(${({theme}) => theme.teal.hsl}, 1);
  width: 100%;
`
const Iframe = styled.iframe`
  display: block;
  width: 100%;
  height: 450px;
`

export const GoogleMap = () => {
  return (
    <Container>
      <Iframe 
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9816.642848542177!2d-85.08702088367335!3d42.98869182371449!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88187b60d03d2da3%3A0x41336fb49b7a38c!2s1110%20W%20Lincoln%20Ave%2C%20Ionia%2C%20MI%2048846!5e0!3m2!1sen!2sus!4v1627672393705!5m2!1sen!2sus"
        width="600"
        height="450"
        loading="lazy"
        title="Google Map"        
      >
      </Iframe>
    </Container>
  )
}
